<template>
    <div id="jobDetail">
        <div class="mcontainer">
            <div class="card">
                <div class="h-44 mb-4 md:h-72 overflow-hidden relative rounded-t-lg w-full">
                    <img :src="news.images" v-if="news.images" alt="" class="w-full h-full absolute inset-0 object-cover">
                </div>
                <div class="p-4">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6">{{ news.title }}</h1>
                    <div class="flex items-center space-x-3 my-3 pb-4 border-b">
                        <img :src="require('@/assets/images/avatars/avatar-1.jpg')" class="w-10 h-10 rounded-full">
                        <div>
                            <div class="text-base font-semibold"> Administrator </div>
                            <div class="text-xs"> Published on {{ news.date }} </div>
                        </div>
                        <div class="absolute right-4 action-buttton" v-if="datauser.is_admin === 1">
                            <router-link :to="{ name: 'UpdateNews', params: {code: news.slug } }" class="btn btn-blue px-4 mr-2" v-b-tooltip.hover title="Update Data">
                                <span class="md:block hidden">Edit</span>
                            </router-link>
                            <button @click="handleDelete" class="btn btn-danger p-2" v-b-tooltip.hover title="Delete Data">
                                <Icon icon="bi:trash" />
                            </button>
                        </div>
                    </div>
                    <div class="space-y-3" v-html="news.description">
                    </div>
                    <br>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        computed: mapState('auth', { datauser: 'user' }),
        components: {
            Icon
        },
        data () {
            return {
                news: []
            }
        },
        created () {
            this.fetchData()
        },
        methods: {
            fetchData () {
                const $this = this
                axios.get('article/' + this.$route.params.code).then(function (response) {
                    $this.news = response.data.result
                })
            },
            handleDelete: function () {
                const parents = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('article/delete/' + this.news.id).then(function (response) {
                            if (response.data.status) {
                                parents.$router.push({ name: 'News' })
                            }
                        }).catch(function () {
                            parents.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                    }
                })
            }
        }
    }
</script>
